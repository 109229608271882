<template>
  <div class="plan-change-step1-container">
    <div class="step-title">플랜 선택</div>
    <template v-if="service.plan.trial">
      <div v-if="!hasOrder" class="box-trial">👏 첫 플랜결제 최대 44% 추가 할인</div>
      <div v-else
           class="box-trial">무료체험 {{ service.plan.d_day }}일 남았습니다.
        <b>플랜을 결제하지 않으시면 서비스가 삭제됩니다.</b>
      </div>
    </template>
    <!-- 결제 기간 선택 -->
    <div class="flex-center">
      <div class="tab-wrapper">
        <div v-for="(opt, idx) in option" :key="`opt-${idx}`"
          class="tab-item unselect"
             :class="{'selected': dateUnit === opt.value}"
             @click="dateUnit=opt.value">{{ opt.label }}</div>
      </div>
    </div>

    <div class="grid-plan">
      <div class="grid-th">
        <div class="grid-item"></div>
        <div v-for="(item,idx) in th" :key="`th-${idx}`"
             class="grid-item">{{ item.title }}</div>
        <div class="grid-item">결제주기 요금제</div>
      </div>

      <div v-for="(item,idx) in plans" :key="`plan-${idx}`"
        class="grid-td" :class="{'current': item.membership === service.plan.membership }">
        <!-- 플랜 -->
        <div class="grid-item">
          <div style="width:100%">
            <div class="flex" style="justify-content: space-between">
              <div class="h7">{{ item.membership }}</div>
              <div v-if="item.membership === service.plan.membership"
                class="tag-usage"><span v-if="service.plan.trial">무료체험 </span>이용중</div>
            </div>
            <div class="body6 sub3" style="margin:12px 0">{{ item.desc }}</div>
            <div v-if="subsItem(item).discount_rate>0"
              class="sub4 size-12" style="margin-bottom:2px">
              <span class="line-through">{{ subsItem(item).org_price|currencyNum }}</span>원</div>
            <div>
              <span v-if="subsItem(item).discount_rate > 0"
                    class="h8 primary" style="margin-right:4px">{{ subsItem(item).discount_rate }}%</span>
              <span class="h8">{{ subsItem(item).price|currencyNum }}</span>
              <span>원</span><span style="color:#aeaeae">/월</span>
            </div>
            <button class="button is-primary button-select" @click="clickPlan(item)">선택</button>
          </div>
        </div>
        <!-- 사용량 -->
        <div v-for="(child,cidx) in th" :key="`child-${cidx}`"
             class="grid-item">{{ item.usage[child.key] }}{{ child.unit }}</div>

        <!-- 결제주기 요금제 -->
        <div v-for="(sitem,sidx) in item.subscriptions" :key="`subscribe-${sidx}`"
            class="grid-item">
          <div class="item-price">
            <div>
              <div class="size-13"
                   :class="{'current-text': item.membership === service.plan.membership &&
                                            sitem.date_unit === service.plan.date_unit}">{{ dateUnitTxt(sitem) }}</div>
              <div v-if="item.membership === service.plan.membership && sitem.date_unit === service.plan.date_unit"
                  class="tag-usage-s">이용중</div>
            </div>
            <div style="text-align: right">
              <div v-if="sitem.discount_rate>0"
                   class="sub4 size-12" style="margin-bottom:2px">
                <span class="line-through">{{ sitem.org_price|currencyNum }}</span>원</div>
              <div>
                <span v-if="sitem.discount_rate > 0"
                      class="h8 primary" style="margin-right:4px">{{ sitem.discount_rate }}%</span>
                <span class="h8">{{ sitem.price|currencyNum }}</span>
                <span>원</span><span style="color:#aeaeae">/1개월</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
  import UserAPIMixin from "../../../mixins/UserAPIMixin";

  export default {
    name: "PlanChangeStep1",
    props: {
      service: {
        type: Object
      }
    },
    mixins: [
      UserAPIMixin
    ],
    computed: {

    },
    methods: {
      getHasOrder() {
        this.request.user.get('launchpack/has_order').then(res => {
          this.hasOrder = res.data.result;
        })
      },
      clickPlan(item) {
        item.date_unit = this.dateUnit;
        this.$emit('next', this.cloneItem(item));
      },
      getData() {
        this.request.user.get(`launchpack/v1/service_plan?key=${this.service.package}`).then(res => {
          this.plans = res.data.sort((a,b) => a.id - b.id);
        });
      },
      subsItem(item) {
        return item.subscriptions.find(i => i.date_unit === this.dateUnit);
      },
      dateUnitTxt(item) {
        return `${item.date_unit}개월`
      }
    },
    created() {
      this.dateUnit = this.service.plan.date_unit === 0 ? 1 : this.service.plan.date_unit;
      this.getHasOrder();
      this.getData();
    },
    data() {
      return {
        hasOrder: true,
        dateUnit: 1,
        option: [
          {
            label: '1개월',
            value: 1
          },
          {
            label: '3개월',
            value: 3
          },
          {
            label: '6개월',
            value: 6
          },
          {
            label: '12개월',
            value: 12
          }
        ],
        th: [
          {key: 'user', title: '회원가입 수', unit: '명'},
          {key: 'product', title: '상품 등록 수', unit: '건'},
          {key: 'category', title: '상품 카테고리 수', unit: '개'},
          {key: 'partner', title: '파트너 등록 수', unit: '건'},
          {key: 'admin_account', title: '관리자 계정 갯수', unit: '명'},
          {key: 'front', title: '모바일/PC', unit: ''},
          {key: 'template', title: '블럭템플릿 무제한 이용권', unit: ''}
        ],
        plans: []
      }
    }
  }
</script>

<style lang="stylus" scoped>
  @import '~assets/css/lp_main'

  .plan-change-step1-container
    padding 32px
    padding-bottom 60px
  .step-title
    text-align center
    font-weight 700
    color $main
    font-size 24px
    line-height 36px
    margin-bottom 16px

  .box-trial
    background-color #FFF0F0
    color #FF3B3B
    text-align center
    padding 16px
    border-radius 8px
    margin-bottom 24px
    display flex
    align-items center
    justify-content center

  .tab-wrapper
    display flex
    align-items center
    gap 8px
  .tab-item
    border-radius 50px
    width 160px
    padding 6px 14px
    border 1px solid $gray
    background-color white
    color #363636
    text-align center
    font-size 14px
    line-height 22px
  .selected
    background-color #363636
    border 1px solid #363636
    color white

  .grid-plan
    margin-top 24px
    display grid
    grid-template-columns 195px repeat(3, 1fr)
    border-top 1px solid $main
    border-left 1px solid $gray
  .grid-th
    display grid
    grid-template-rows 195px repeat(7, 44px) 1fr
    .grid-item
      font-weight 400
  .grid-td
    display grid
    grid-template-rows 195px repeat(7, 44px) repeat(4, 62px)
    .grid-item
      font-weight 500

  .grid-item
    border-right 1px solid $gray
    border-bottom 1px solid $gray
    padding 0 16px
    display flex
    align-items center
    font-size 14px

  .current
    .grid-item
      background-color #FFF9F4

  .line-through
    text-decoration line-through

  .button-select
    margin-top 8px
    width 100%

  .item-price
    display flex
    justify-content space-between
    width 100%

  .tag-usage
  .tag-usage-s
    border-radius 4px
    padding 2px 10px
    background-color #EFECFF
    color #7B61FF
    font-size 14px
    font-weight 500
    border 1px solid #7B61FF
  .tag-usage-s
    width 44px
    font-size 12px
    padding 0 4px
    margin-top 4px
  .current-text
    text-align left
    color #7B61FF

  .button.is-primary
    height 36px
</style>
